// MEDIA QUERY MANAGER
/*
0 - 600px:        Phone
600 - 900px:      Tablet landscape
900 - 1200px:     Tablet landscape
[1200 - 1800]:    Normal styles
1800px +:         Big desktop

$breakpoint argument choices:
- phone
- phone-showpage
- tab-port
- tab-land
- big-desktop

1em = 16px

*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (min-width: 20em) and (max-width: 37.5em) {
      @content;
    } //600px
  }

  @if $breakpoint == phone-359 {
    @media (min-width: 20em) and (max-width: 22.4375em) {
      @content;
    } //359px
  }

  @if $breakpoint == phone-406 {
    @media (min-width: 20em) and (max-width: 25.375em) {
      @content;
    } //406px
  }

  @if $breakpoint == max-phone {
    @media (min-width: 37.5em) {
      @content;
    } //600px
  }

  @if $breakpoint == max-618 {
    @media (max-width: 38.625em) {
      @content;
    } //618px
  }

  @if $breakpoint == min-619 {
    @media (min-width: 38.6875em) {
      @content;
    } //619px
  }

  @if $breakpoint == phone-min {
    @media (min-width: 20em) {
      @content;
    } //600px
  }

  @if $breakpoint == phone-showpage {
    @media (min-width: 20em) and (max-width: 25.1em) {
      @content;
    } //402px
  }

  @if $breakpoint == min-layout {
    @media (min-width: 56.25rem) {
      @content;
    } // min-900px
  }

  @if $breakpoint == tab-port {
    @media (max-width: 56.25rem) {
      @content;
    } // max-900px
  }

  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    } // max-1200px
  }

  @if $breakpoint == tab-port-min {
    @media (min-width: 56.25rem) {
      @content;
    } // min-900px
  }

  @if $breakpoint == tab-port-618 {
    @media (min-width: 20em) and (max-width: 38.625rem) {
      @content;
    } // max-618x
  }

  @if $breakpoint == tab-port-618 {
    @media (max-width: 38.625rem) {
      @content;
    } // max-618x
  }

  @if $breakpoint == tab-land-min {
    @media (min-width: 75em) {
      @content;
    } // min-1200px
  }

  @if $breakpoint == tab-land-max {
    @media (max-width: 75em) {
      @content;
    } // max-1200px
  }

  @if $breakpoint == desktop-min {
    @media (min-width: 38.6875em) {
      @content;
    } // min-619px
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } // 1800px
  }
}
