@import './mixins';
@import './reset';

.container {
  display: flex;
  width: 100%;
  background-color: darken(#fafafa, 3%);
}
.sidebar-wrapper {
  width: 30%;
  background-color: #1b1b87;
  border-right: 2px solid rgb(239, 243, 244);
  display: flex;
  justify-content: center;
  color: #fff;
  height: 100%;

  @media (min-width: 43.75em) and (max-width: 56.25em) {
    width: 65%;
  }

  @media (max-width: 43.75em) {
    width: 70%;
    padding: 0 0.8rem;
  }
}
#sidebar {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: 100%;
  justify-content: space-between;

  nav {
    ul {
      font-size: 1.4rem;

      .menu-item-text {
        @media (max-width: 115.5625em) {
          display: none;
        }
      }
    }

    li {
      padding: 2rem 0;

      .fal {
        font-size: 1.3rem;
      }
      a {
        color: #fff;
        text-decoration: none;
      }
      span {
        padding: 0.8rem;
      }

      @media (min-width: 87.5em) {
        padding: 2rem 0.2rem;
      }

      @media (max-width: 40em) {
        padding: 1.2rem 0;
      }
    }

    @media (max-width: 43.75em) {
      position: absolute;
      top: 2rem;
    }

    @media (max-width: 40em) {
      position: static;
      margin-bottom: 250%;
    }
  }

  .contact-info {
    margin-bottom: 4rem;
    position: absolute;
    bottom: 4rem;

    ul {
      display: flex;
      li {
        padding: 1rem;
        @media (max-width: 40em) {
          padding: 0.6rem 0;
        }
      }
      @media (max-width: 115.5625em) {
        flex-direction: column;
      }
    }

    @media (max-width: 43.75em) {
      bottom: 8%;
    }

    @media (max-width: 40em) {
      margin-top: 0;
      margin-bottom: 0;
      position: static;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 40em) {
    margin-top: 0.8rem;
    justify-content: flex-start;
  }
}
.main-content {
  align-self: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .about-me {
    width: 80%;
    color: #374151;
    p {
      font-weight: 500;
      line-height: 1.6;
      padding: 0 1rem 1rem 1rem;
    }

    @media (max-width: 68.75em) {
      width: 100%;
    }
  }

  .content {
    & > div {
      margin-bottom: 10rem;
    }

    &:first-child {
      margin-top: 5.1rem;

      @media (max-width: 40em) {
        margin-top: 1.1rem;
      }
    }

    width: 70%;

    @media (max-width: 68.75em) {
      width: 90%;
    }

    @media (min-width: 68.8125em) and (max-width: 93.75em) {
      width: 80%;
    }
  }
}

.name {
  height: 3.3rem;
  display: flex;
  margin-bottom: 0.6rem;
}

.long-dash {
  display: block;
  width: 3rem;
  border-top: 2px solid #4c40f7;
  align-self: flex-end;
  height: 1.6rem;
  margin-right: 1rem;
}

.intro-text {
  color: #19162b;
  font-size: 3.5rem;
}

.name-text {
  align-self: center;
  color: #4c40f7;
  font-size: 1.5rem;
}

.project {
  margin-bottom: 2rem;
  background-color: #fafafa;
  padding: 1.3rem 1.1rem 0 1.1rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 11px 10px 38px 0px;

  &.pokedexelus {

    .project-description { 
      display: flex;
      flex-direction: column;
      align-items: center;
      p:nth-child(2){
        line-height: 2.5;
      }
    }
  }

  a {
    color: #1b1b87;
    text-decoration: none;
  }
  &:not(:last-child) {
    margin-bottom: 4rem;
  }
  .project-description {
    display: flex;
    justify-content: center;
    padding: 2.5rem 0;
    font-weight: 500;
    p {
      text-align: center;
    }
  }
  .project-links-wrapper {
    display: flex;
    justify-content: center;

    .project-links {
      display: flex;
      max-width: 15rem;
      max-width: 15rem;
      padding-bottom: 2.5rem;

      span {
        display: block;
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
    }
  }
  h2 {
    font-size: 1.2rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
  a {
    width: 1000px;
  }
  img {
    width: 100%;
    max-width: 1303px;
    height: auto;
    object-fit: cover;
    display: block;
    margin-bottom: 8px;
  }
  .project-technologies {
    display: flex;
    justify-content: center;
    padding: 0 0.5rem 2.5rem 0.5rem;

    ul {
      display: flex;
      justify-content: center;
      font-size: 0.8rem;
      border-radius: 5px;

      li {
        display: flex;
        flex-direction: column;
        width: 8rem;
        padding: 0.5rem 0;
        background-color: darken(#fafafa, 3%);
        justify-content: center;
        align-items: center;
        border-radius: 0.3rem;
        font-weight: 500;
        height: 6rem;
        
        &:not(:last-child) {
          margin-right: 1rem;
        }

        @media (max-width: 50em) {
          flex-basis: 40%;
          margin-right: 1rem0;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
      @media (max-width: 50em) {
        width: 100%;
        flex-wrap: wrap;
      }
    }
    img {
      height: 2rem;
      width: 2rem;
      margin-bottom: 0.3rem;
    }
  }
}

.skills-section {
  .skills {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 1.1rem;
    width: 100%;

    h3 {
      margin-bottom: 1rem;
      > span {
        padding-left: 0.5rem;
        margin-bottom: 0.2rem;
      }
    }

    ul {
      font-size: 1rem;
      font-weight: 400;
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-radius: 3px;
      background-color: #fafafa;
      padding: 2rem 1rem 0 1rem;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.12) 11px 10px 38px 0px;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2rem;
        font-weight: 500;

        img {
          height: 4rem;
          width: 4rem;
          padding-bottom: 0.5rem;
          object-fit: cover;
        }

        @media (max-width: 75em) {
          flex-basis: 30%;
        }
      }

      @media (max-width: 75em) {
        flex-wrap: wrap;
      }
    }

    .frontend {
      margin-bottom: 6rem;
      margin-top: 1.5rem;
    }
  }
}

.footer-wrapper {
  padding: 2rem;
  .footer {
    font-weight: normal;
    font-size: 0.8rem;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}
